import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import SignUp from './home/SignUp';
import Dashboard from './home/Dashboard';

function App(props) {
  return (
    <Switch>
      <Route path='/signup' component={SignUp} />
      <Route path='/dashboard' component={Dashboard} />
    </Switch>
  );
}

export default App;
