import React from "react";

const ProfileLink = (props) => {

    const getStyles = (id) => {
        switch(id) {
            case 'image':
                return { height: '40px', width: '40px', borderRadius: '50%' };
            case 'name':
                return { };
            case 'link':
                return { textDecoration: 'none', color: 'black' }
            default:
            return {};
        }
    }

    return (
        <a style={getStyles('link')} href={`/users/${props.user._id}`}>
            <img style={getStyles('image')} alt={props.user.fullName} src={`data:image/${props.user.image.contentType};base64,${props.user.image.data}`} />
            &nbsp;
            <span className="align-middle">{props.user.fullName}</span>
        </a>
    );
}

export default ProfileLink;