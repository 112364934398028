import React, { useState } from "react";
import CardDeck from 'react-bootstrap/CardDeck';
import Container from 'react-bootstrap/Container';
import TopBar from '../common/TopBar';
import TicketCard from "./TicketCard";
import { GetTickets } from '../mocks/Tickets';

const Dashboard = (props) => {

    const [tickets, getTickets] = useState(GetTickets());

    return (
        <div>
        <TopBar />
            <Container>
                <CardDeck>
                    { tickets.map((ticket) => {
                        return <TicketCard key={ticket._id} ticket={ticket} />
                    })}
                </CardDeck>
            </Container>
        </div>
    );
}

export default Dashboard;