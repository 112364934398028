import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ProfileLink from '../common/ProfileLink';

const TicketCard = (props) => {

    const getStyles = (id) => {
        switch(id) {
            case 'card':
                return { width: '100%', height: 'auto' };
            case 'image':
                return { width: '100%', height: 'auto' };
            case 'expired': 
                return { 
                         visibility: props.ticket.drawingDate > Date.now() ? 'visible' : 'hidden',
                         color: 'red'  
                        };
            case 'link':
                return { textDecoration: 'none', color: 'black' };
            default:
            return {};
        }
    }

    return (
        <Col lg="4" md="6" sm="12" style={{ paddingTop: '30px'}}>
            <Card style={getStyles('card')}>
                <Card.Header>
                <a style={getStyles('link')} href={`/pools/${props.ticket.pool._id}`}>
                {props.ticket.pool.name}
                </a>
                </Card.Header>
                <a  href={`/tickets/${props.ticket._id}`}>
                <Card.Img style={getStyles('image')} variant="top" src={`data:image/${props.ticket.image.contentType};base64,${props.ticket.image.data}`} />
                </a>
                <Card.Body>
                    <Card.Title>
                        <ProfileLink user={ props.ticket.user } />
                    </Card.Title> 
                    <Card.Text>                    
                        {props.ticket.note}
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">
                    {props.ticket.drawing} <br/>
                    {props.ticket.drawingDate} <span id="expired" style = {getStyles('expired')}>Expired</span>
                    </small>
                </Card.Footer>
            </Card>
        </Col>
    );
}

export default TicketCard;