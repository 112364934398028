import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ProfileLink from "./ProfileLink";
import { ProfileImageSrc } from "../mocks/ProfileImageSrc";

const Topbar = () => {

    const [currentUser, setCurrentUser] = useState(
        {
            _id: 1234,
            image: {
                data: ProfileImageSrc(),
                contentType: 'jpeg'
            },
            fullName: "Sheikh Rahman"
        }
    );

    return (
        
        <Navbar sticky="top" style={{ backgroundColor: "white" }}>
            <Container>
            <Navbar.Brand href="/dashboard">WLF</Navbar.Brand>|&nbsp;&nbsp;
            <Navbar.Text href="/dashboard">Whole Lotto Fun</Navbar.Text>
            <Nav className="ml-auto">
            <ProfileLink user={currentUser} />
            </Nav>
            </Container>
        </Navbar>

    );
}

export default Topbar;