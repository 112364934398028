import React from "react";

const SignUp = () => {
    return (
        <div>

        </div>
    );
}

export default SignUp;